import { render, staticRenderFns } from "./ListWizfitGBModal.vue?vue&type=template&id=030d2ce7&scoped=true"
import script from "./ListWizfitGBModal.vue?vue&type=script&lang=js"
export * from "./ListWizfitGBModal.vue?vue&type=script&lang=js"
import style0 from "./ListWizfitGBModal.vue?vue&type=style&index=0&id=030d2ce7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030d2ce7",
  null
  
)

export default component.exports