<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Wizfit Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListWizfitGBModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div style="text-align: justify; font-size: 16px">
            <hr
              style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
            />
            <br />
            {{ this.campaignData.gold_ball_giveaway_date_str_3 }}
            <v-icon
              dark
              class="icons"
              color="#6B6B6B"
              size="22"
              @click="editCampaign()"
              v-if="this.adminAccess != 'Player'"
            >
              mdi-pencil
            </v-icon>
            <hr
              style="
                height: 2px;
                border-width: 0;
                color: gray;
                background-color: gray;
              "
            />
            <br />
            <div
              v-if="this.campaignData.goldball_player_confirmations.length != 0"
            >
              <strong style="margin-right: 357px; font-size: 17px"
                >Players:
              </strong>
              <div
                v-for="(monthlySlot, index) in goldballplayeritems1"
                :key="index"
              >
                <tr class="students">
                  <td>{{ monthlySlot.player_name }}</td>
                  &nbsp;&nbsp;
                  <td
                    style="color: green; font-weight: 600"
                    v-if="monthlySlot.confirmation_status == 'confirmed'"
                  >
                    Confirmed
                  </td>
                  <td style="color: red; font-weight: 600" v-else>
                    Not Confirmed
                  </td>
                </tr>
              </div>
            </div>
            <div
              v-if="this.campaignData.goldball_player_confirmations.length == 0"
            >
              <tr class="students">
                <strong>Players: </strong>
                <td
                  v-for="(item, i) in goldballplayeritems"
                  :key="i"
                  v-text="item.name"
                  class="student show"
                ></td>
              </tr>
              <br />
            </div>
            <br />
            <strong>Category:</strong> Wizfit
            <br />
            <br />
            <strong>Location:</strong>
            {{ this.campaignData.organization_name }} <br />
            <br />
            <div v-if="this.campaignData.campaign_type == 'school'">
              <strong>Address:</strong>
              {{ this.campaignData.school_address }} <br />
              <br />
            </div>
            <strong>Time:</strong>
            {{ this.campaignData.gold_ball_start_time_str }} -
            {{ this.campaignData.gold_ball_end_time_str }} <br />
            <br />
            <strong>Student Enrollment:</strong>
            {{ this.campaignData.total_student }}/{{
              this.campaignData.school_info_total_student
            }}
            <br />
            <br />
            <strong>Note: </strong><br />
            <div v-html="this.campaignData.notes"></div>
            <br />
            <br />
            <div v-if="this.campaignData.campaign_type == 'district'">
              <h4>School List</h4>
              <ul>
                <li v-for="school in schoolListData" :key="school.id">
                  <h4>{{ school.school_name }}</h4>
                  <p>School Address:{{ school.school_address }}</p>
                  <p>
                    Pre-registration date:
                    {{ school.pre_registration_date_str }} ,{{
                      school.pre_registration_start_time_str
                    }}-{{ school.pre_registration_end_time_str }}
                  </p>
                  <p>
                    Kick-off date: {{ school.kickoff_date_str }} ,{{
                      school.kickoff_starttime_str
                    }}-{{ school.kickoff_endtime_str }}
                  </p>
                  <p>
                    Special-giveway date:
                    {{ school.kickoff_date_golden_ball_date_strstr }} ,{{
                      school.golden_ball_start_time_str
                    }}-{{ school.golden_ball_end_time_str }}
                  </p>
                  <p>
                    Graduation date: {{ school.graduation_date_str }} ,{{
                      school.graduation_starttime_str
                    }}-{{ school.graduation_endtime_str }}
                  </p>
                  <p>Low Goal: {{ school.low_goal }}</p>
                  <p>Middle Goal: {{ school.middle_goal }}</p>
                  <p>Top Goal: {{ school.top_goal }}</p>
                  <p>Low Goal Purpose: {{ school.low_goal_purpose }}</p>
                  <p>Middle Goal Purpose: {{ school.middle_goal_purpose }}</p>
                  <p>Top Goal Purpose: {{ school.top_goal_purpose }}</p>
                  <p>Other Incentive:</p>
                  <span v-html="school.other_incentive"></span>
                  <br />
                  <p>Notes:</p>
                  <div v-html="school.notes"></div>
                  <p>Money Raised: {{ school.orignal_total_fund_raised_50 }}</p>
                  <p>Financial Goal: {{ school.school_goal_50 }}</p>
                </li>
              </ul>
            </div>

            <div v-if="this.campaignData.campaign_type == 'school'">
              <strong>Money Raised:</strong>
              {{ this.campaignData.original_total_fund_raised_50 }}
              <br />
              <strong>Financial Goal:</strong>
              {{ this.campaignData.total_goal_50 }}
            </div>
            <br />
            <br />
            <strong>Description: </strong>
            <div v-html="this.campaignData.special_message"></div>
            <br />
            <br />
            <div>
              <strong>School Goals And Incentives:</strong><br />
              Low Goal Condition:&nbsp;&nbsp;{{ this.campaignData.low_goal }}
              <br />
              Low Goal Purpose:&nbsp;&nbsp;{{
                this.campaignData.low_goal_purpose
              }}
              <br />
              Middle Goal Condition:&nbsp;&nbsp;{{
                this.campaignData.middle_goal
              }}
              <br />
              Middle Goal Purpose:&nbsp;&nbsp;{{
                this.campaignData.middle_goal_purpose
              }}
              <br />
              Top Goal Condition:&nbsp;&nbsp;{{ this.campaignData.top_goal }}
              <br />
              Top Goal Purpose:&nbsp;&nbsp;{{
                this.campaignData.top_goal_purpose
              }}
              <br />
              <br />
              <strong>Other Incentives:</strong>
              <div v-html="this.campaignData.other_incentive"></div>
              <!-- <strong>School Notes: </strong>
                  <div v-html="this.campaignData.school_notes"></div>-->
            </div>
            <br />
            <br />
            <strong style="font-size: 20px; text-decoration: underline"
              >Contact Info:</strong
            >
            <br />
            <strong>Name:</strong>{{ this.campaignData.host_name }}
            <br />
            <strong>Email:</strong> {{ this.campaignData.host_email }}
            <br />
            <strong>Phone No.:</strong>{{ this.campaignData.host_phone }}
            <hr
              style="
                height: 2px;
                border-width: 0;
                color: gray;
                background-color: gray;
              "
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { API_ADMIN_CAMPAIGN_MANAGEMENT_GET } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../../constants/urls";
export default {
  name: "ListWizfitGBModal",
  components: {},
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      formLoading: false,
      campaignData: {},
      goldballplayeritems: [],
      goldballplayeritems1: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListWizfitGB",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListWizfitGBModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listWizfitGBModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListWizfitGBModal: "calendarManagement/toggleListWizfitGBModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getCampaignDetail();
    },
    closeCallBackModal() {},

    getCampaignDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.campaignData = res.data.campaign_detail;
        var wizfitdatess = new Date(this.campaignData.start_date);
        var datewizfit = new Date(wizfitdatess);
        this.wizfitdisplaydate = datewizfit.toDateString();
        if (this.campaignData.kick_off_player_list != null) {
          this.kickoffplayeritems = this.campaignData.kick_off_player_list;
        }
        if (this.campaignData.gold_ball_player_list != null) {
          this.goldballplayeritems = this.campaignData.gold_ball_player_list;
        }
        if (this.campaignData.goldball_player_confirmations.length != 0) {
          this.goldballplayeritems1 =
            this.campaignData.goldball_player_confirmations;
        }
        if (this.campaignData.graduation_player_list != null) {
          this.graduationplayeritems = this.campaignData.graduation_player_list;
        }
        if (this.campaignData.pre_registration_player_list != null) {
          this.preregistrationplayeritems =
            this.campaignData.pre_registration_player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["campaign_id"] =
        this.$store.state.calendarManagement.listWizfitGBModal.campaignID;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
    editCampaign() {
      let routerData = this.$router.resolve({
        // name: ROUTER_URL.adminPanel.children.newCampaign.name,
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: {
          type: "edit",
          id: this.$store.state.calendarManagement.listWizfitGBModal.campaignID,
        },
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
